import React, { useState } from "react";
import logo from "../../Logo.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";
const Account = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate(); // Moved inside the function

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setFormData({ username: "", email: "", password: "" });
    setErrors({});
  };
  const notify = () => {
    return new Promise((resolve) => {
      toast.success(`${isLogin ? "Login" : "Registration"} successful`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => resolve(), // Resolve the promise when the toast closes
      });
    });
  };

  const validate = () => {
    let errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (!formData.password) errors.password = "Password is required";

    if (!isLogin && !formData.username)
      errors.username = "Username is required";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      try {
        const response = await axios.post(
          `https://core.juliabot.com/api/v1/user/${
            isLogin ? "login" : "register"
          }/`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setFormData({ username: "", email: "", password: "" });
          await notify();
          if (isLogin) {
            navigate("/chat");
            localStorage.setItem("access", response.data.access);
            localStorage.setItem("refresh", response.data.refresh);
          } else {
            setIsLogin(true);
          }
        } else {
          handleError(response);
        }
      } catch (error) {
        if (error.response) {
          // The request was made, and the server responded with a status code
          handleError(error.response);
        } else if (error.request) {
          // The request was made, but no response was received (likely a CORS issue)
          console.error(
            "No response received, possible CORS issue:",
            error.request
          );
          handleError({
            status: 0,
            message: "No response received. Please check CORS settings.",
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error:", error.message);
          handleError({ status: 0, message: error.message });
        }
      }
    }
  };

  const handleError = (response) => {
    const newErrors = { ...errors }; // Create a copy of the errors object

    if (response.status === 401) {
      console.error(
        `${isLogin ? "login" : "registration"} failed:`,
        response.status
      );
      newErrors.incorrect = "Incorrect credentials.";
    } else if (response.status === 400 && !isLogin) {
      console.error(
        `${isLogin ? "login" : "registration"} failed:`,
        response.status
      );
      newErrors.email = response.data.email;
      newErrors.username = response.data.username;
    } else {
      newErrors.incorrect = `Error during ${
        isLogin ? "login" : "registration"
      }: ${response.status}`;
    }

    setErrors(newErrors);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex min-h-screen justify-center items-center bg-white ">
        <div className="w-full max-w-md sm:mx-0 mx-2 p-4 space-y-6  bg-gray-100 border-2 border-gray-200 rounded-2xl shadow-lg">
          <div className="flex justify-center">
            <img src={logo} alt="Logo" className="h-12 " />
          </div>
          <h2 className="text-center text-2xl font-bold">
            {isLogin ? "Log In" : "Register"}
          </h2>
          <form className="mt-8 " noValidate onSubmit={handleSubmit}>
            {!isLogin && (
              <div className="mt-2">
                <label className="block text-md font-medium text-gray-700">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  placeholder="Enter your username"
                  value={formData.username}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 border ${
                    errors.username || errors.incorrect
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-lg focus:outline-none focus:ring focus:ring-blue-500`}
                />
                {errors.username && (
                  <p className="text-red-500 text-md">{errors.username}</p>
                )}
              </div>
            )}
            <div className="mt-2">
              <label className="block text-md font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                className={`w-full px-3 py-2 border ${
                  errors.email || errors.incorrect
                    ? "border-red-500"
                    : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring focus:ring-blue-500`}
              />
              {errors.email && (
                <p className="text-red-500 text-md">{errors.email}</p>
              )}
            </div>
            <div className="mt-2">
              <label className="block text-md font-medium text-gray-700">
                Password
              </label>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
                className={`w-full px-3 py-2 border ${
                  errors.password || errors.incorrect
                    ? "border-red-500"
                    : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring focus:ring-blue-500`}
              />
              {errors.password && (
                <p className="text-red-500 text-md">{errors.password}</p>
              )}
            </div>
            {errors.incorrect && (
              <p className="text-red-500 text-md text-center">
                {errors.incorrect}
              </p>
            )}
            {isLogin && (
              <div className="flex flex-row justify-end mt-1">
                <NavLink
                  to="/resetpassword"
                  className={"text-blue-500 hover:underline"}
                >
                  forget password?
                </NavLink>
              </div>
            )}
            <button
              type="submit"
              className="w-full py-2 px-4 text-white bg-blue-500 hover:bg-blue-700 rounded-lg mt-4"
            >
              {isLogin ? "Log In" : "Register"}
            </button>
          </form>
          <div className="text-center">
            <button
              onClick={toggleForm}
              className="text-blue-500 hover:underline"
            >
              {isLogin ? "Don't have an account?" : "Already have an account?"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
