import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get("token");

  /**
   * Custom email validation function using a more robust regex pattern.
   */
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validateEmailForm = () => {
    const formErrors = {};

    if (!email.trim()) {
      formErrors.email = "Email is required";
    } else if (!isValidEmail(email)) {
      formErrors.email = "Please enter a valid email address";
    }

    return formErrors;
  };

  const validatePasswordForm = () => {
    const formErrors = {};

    if (!newPassword.trim()) {
      formErrors.newPassword = "New password is required";
    } else if (newPassword.length < 8) {
      formErrors.newPassword = "Password must be at least 8 characters";
    }

    if (!confirmPassword.trim()) {
      formErrors.confirmPassword = "Confirm password is required";
    } else if (newPassword !== confirmPassword) {
      formErrors.confirmPassword = "Passwords do not match";
    }

    return formErrors;
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateEmailForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      try {
        const response = await fetch(
          "https://core.juliabot.com/api/v1/user/resetpassword/",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
          }
        );

        const data = await response.json();
        if (response.ok) {
          toast.info("Check your email for the reset link.", {
            position: "top-center",
            autoClose: 5000,
          });
          setEmail("");
        } else if (response.status === 400) {
          toast.error(
            "We couldn't find an account associated with that email. Please try a different e-mail address.",
            {
              position: "top-center",
              autoClose: 5000,
            }
          );
        } else {
          toast.error(
            data.message || "Failed to send reset link. Please try again.",
            {
              position: "top-center",
              autoClose: 5000,
            }
          );
        }
      } catch (error) {
        console.error("Error sending reset email: ", error);
        toast.error("An unexpected error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 5000,
        });
      }
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validatePasswordForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      try {
        const response = await fetch(
          "https://core.juliabot.com/api/v1/user/resetpassword/confirm/",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              password: newPassword,
              token,
            }),
          }
        );

        if (response.ok) {
          setNewPassword("");
          setConfirmPassword("");
          toast.success("Password changed successfully!", {
            position: "top-center",
            autoClose: 5000,
          });
          navigate("/account");
        } else {
          const data = await response.json();
          if (data.password[0] === "This password is too common.") {
            setErrors({
              newPassword: "Use a strong password.",
            });
          } else {
            toast.error(
              data.message || "Failed to reset password. Please try again.",
              {
                position: "top-center",
                autoClose: 5000,
              }
            );
          }
        }
      } catch (error) {
        console.error("Error resetting password: ", error);
        toast.error("An unexpected error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 5000,
        });
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="flex justify-center items-center min-h-screen bg-gray-100 px-4">
        {!token ? (
          <form
            onSubmit={handleEmailSubmit}
            className="bg-white p-6 md:p-8 rounded-lg shadow-lg w-full max-w-md"
            noValidate
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
              Request Password Reset
            </h2>

            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Email Address
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (errors.email) {
                    setErrors({ ...errors, email: "" });
                  }
                }}
                className={`w-full p-3 border ${
                  errors.email ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:border-blue-500`}
                placeholder="example@example.com"
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Send Reset Link
            </button>
          </form>
        ) : (
          <form
            onSubmit={handlePasswordSubmit}
            className="bg-white p-6 md:p-8 rounded-lg shadow-lg w-full max-w-md"
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
              Reset Password
            </h2>

            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                New Password
              </label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  if (errors.newPassword) {
                    setErrors({ ...errors, newPassword: "" });
                  }
                }}
                className={`w-full p-3 border ${
                  errors.newPassword ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:border-blue-500`}
                placeholder="Enter new password"
              />
              {errors.newPassword && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.newPassword}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Confirm Password
              </label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  if (errors.confirmPassword) {
                    setErrors({ ...errors, confirmPassword: "" });
                  }
                }}
                className={`w-full p-3 border ${
                  errors.confirmPassword ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:border-blue-500`}
                placeholder="Confirm new password"
              />
              {errors.confirmPassword && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.confirmPassword}
                </p>
              )}
            </div>

            <button
              type="submit"
              className="w-full bg-green-600 text-white py-3 rounded-lg hover:bg-green-700 transition duration-300"
            >
              Reset Password
            </button>
          </form>
        )}
      </div>
    </>
  );
};

export default ResetPassword;
