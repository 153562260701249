import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";

const ChatInput = ({ onSubmit }) => {
  const [value, setValue] = useState("");
  const textareaRef = useRef(null);
  const maxRows = 10; // Maximum number of rows
  const rowHeight = 24; // Estimated height of one row (adjust if necessary)

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (value.trim()) {
      onSubmit(value);
      setValue("");
    }
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Temporarily set height to auto to shrink if needed
      textarea.style.height = "auto";
      // Calculate the height based on scrollHeight
      const newHeight = Math.min(textarea.scrollHeight, rowHeight * maxRows);
      textarea.style.height = `${newHeight}px`;
    }
  }, [value]);

  return (
    <div className="relative flex flex-row border-2 rounded-md border-gray-400 mt-1 p-1">
      <textarea
        ref={textareaRef}
        className="w-full border-gray-400 border-1 text-lg py-1 pl-2 focus:outline-none focus:bg-gray-200 rounded-md resize-none"
        placeholder="Ask me anything..."
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        rows="1"
      />
      <div className="flex items-center  bottom-1 right-1">
        <button
          className="flex items-center justify-center mx-1 rounded-md bg-gray-300 hover:bg-gray-400 p-2"
          onClick={handleSubmit}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
