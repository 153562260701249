import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [accessToken] = useState(localStorage.getItem("access"));
  const navigate = useNavigate();
  const validateForm = () => {
    const newErrors = {};

    if (!oldPassword) newErrors.oldPassword = "Old password cannot be empty.";
    if (!newPassword) newErrors.newPassword = "New password cannot be empty.";
    if (!confirmPassword)
      newErrors.confirmPassword = "Confirm password cannot be empty.";
    if (newPassword && confirmPassword && newPassword !== confirmPassword)
      newErrors.confirmPassword = "Passwords do not match.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const formData = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };

    try {
      const response = await fetch(
        "https://core.juliabot.com/api/v1/user/change_password/",
        {
          method: "PUT",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      // Check if the response has content
      const data = response.status !== 204 ? await response.json() : {};

      if (response.status === 204) {
        // Assuming a successful password change will return status 200
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        toast.success("Password changed successfully!", {
          position: "top-center",
          autoClose: 3000,
        });
        setTimeout(() => {
          localStorage.clear();
          navigate("/account");
        }, 3000);
      } else if (
        response.status === 400 &&
        data.msg &&
        data.msg[0] === "Wrong password."
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          oldPassword: "Old password is Incorrect.",
        }));
      } else {
        toast.error("Something went wrong. Please try again.", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="flex min-h-screen justify-center items-center bg-white ">
        <div className="w-full max-w-md sm:mx-0 mx-2 p-4 space-y-3 bg-gray-100 border-2 border-gray-200 rounded-2xl shadow-lg">
          <h2 className="text-center text-2xl">Change Password</h2>
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Old Password
            </label>
            <input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className={`w-full p-3 border ${
                errors.oldPassword ? "border-red-500" : "border-gray-300"
              } rounded-lg focus:outline-none focus:border-blue-500`}
              placeholder="Old Password..."
            />
            {errors.oldPassword && (
              <p className="text-red-500 text-sm">{errors.oldPassword}</p>
            )}
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              New Password
            </label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className={`w-full p-3 border ${
                errors.newPassword ? "border-red-500" : "border-gray-300"
              } rounded-lg focus:outline-none focus:border-blue-500`}
              placeholder="New Password..."
            />
            {errors.newPassword && (
              <p className="text-red-500 text-sm">{errors.newPassword}</p>
            )}
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Confirm Password
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={`w-full p-3 border ${
                errors.confirmPassword ? "border-red-500" : "border-gray-300"
              } rounded-lg focus:outline-none focus:border-blue-500`}
              placeholder="Confirm Password..."
            />
            {errors.confirmPassword && (
              <p className="text-red-500 text-sm">{errors.confirmPassword}</p>
            )}
          </div>
          <div className="flex items-center justify-center">
            <button
              className="w-full text-center hover:text-black p-2 bg-yellow-500 hover:bg-yellow-400 rounded-md text-white"
              onClick={handleSubmit}
            >
              Change
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
