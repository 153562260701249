import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Plan = ({ position, features, planName, ChoosePlan, priceEuro }) => {
  return (
    <>
      <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
        <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
          <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
            {planName}
          </h2>
          <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">
            {`${priceEuro} € `}
          </h1>
          {Object.entries(features).map(([key, value]) => (
            <p key={key} className="flex items-center text-gray-600 mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {value}
            </p>
          ))}
          {planName !== "Start" && (
            <button
              className="mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded"
              onClick={() => {
                ChoosePlan(position);
              }}
            >
              Choose Plan
            </button>
          )}
        </div>
      </div>
    </>
  );
};
function Pricing() {
  const [isAnnually, setIsAnnually] = useState(false);
  const [plans, setPlans] = useState(null);
  const [accessToken] = useState(localStorage.getItem("access"));
  const [refreshToken] = useState(localStorage.getItem("refresh"));
  const navigate = useNavigate();
  const toggleBilling = () => {
    setIsAnnually(!isAnnually);
  };
  const ChoosePlan = async (planId) => {
    try {
      const response = await axios.post(
        `https://core.juliabot.com/api/v1/payment/checkout/session/`,
        {
          planId: planId + 1,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.data;
      if (response.status === 201) {
        // Use window.location.href for external URLs
        window.location.href = data.data.url;
      }
    } catch (error) {
      // Something happened in setting up the request that triggered an Error
      console.error("Error:", error.message);
    }
  };

  const GetPlans = async () => {
    try {
      const response = await fetch(
        `https://core.juliabot.com/api/v1/payment/price_plans/`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();

      if (response.ok) {
        setPlans(data);
      } else {
        console.error("Failed to fetch plans");
        navigate("/chat");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    if (!(accessToken && refreshToken)) {
      navigate("/account");
    }
    GetPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <>
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
              Pricing
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500">
              Choose the plan that suits your needs.
            </p>
            <div className="flex mx-auto border-2 border-indigo-500 rounded overflow-hidden mt-6">
              <button
                className={`py-1 px-4 ${
                  !isAnnually ? "bg-indigo-500 text-white" : ""
                } focus:outline-none`}
                onClick={toggleBilling}
              >
                Monthly
              </button>
              <button
                className={`py-1 px-4 ${
                  isAnnually ? "bg-indigo-500 text-white" : ""
                } focus:outline-none`}
                onClick={toggleBilling}
              >
                Annually
              </button>
            </div>
          </div>
          <div className="flex flex-wrap -m-4">
            {isAnnually
              ? plans &&
                plans.yearlyPlans
                  .sort((a, b) => a.position - b.position)
                  .map((plan, index) => (
                    <Plan
                      key={index}
                      ChoosePlan={ChoosePlan}
                      priceEuro={plan.priceEuro}
                      planName={plan.planName}
                      features={plan.features}
                      position={plan.position}
                    />
                  ))
              : plans &&
                plans.monthlyPlans
                  .sort((a, b) => a.position - b.position)
                  .map((plan, index) => (
                    <Plan
                      key={index}
                      ChoosePlan={ChoosePlan}
                      priceEuro={plan.priceEuro}
                      planName={plan.planName}
                      features={plan.features}
                      position={plan.position}
                    />
                  ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Pricing;
