import React from "react";
import { NavLink } from "react-router-dom";

const PageNotFound = () => {
  return (
    <>
      <div className="h-screen flex flex-col items-center justify-center">
        <h1 className="text-center text-7xl my-2">404</h1>
        <h2 className="text-center text-gray-500 text-xl my-2">
          Page Doesn't Exist
        </h2>
        <div className="flex items-center justify-center">
          <NavLink
            to={"/chat"}
            className={
              "bg-slate-400 hover:bg-slate-500 py-2 px-4 text-white rounded-md my-4"
            }
          >
            Back to Home
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
